/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-card-index-CardAntd .ant-card-body {
  padding: 10px;
}
.antd-pro-component-dashs-card-index-CardAntd .ant-form-item-label,
.antd-pro-component-dashs-card-index-CardAntd .ant-legacy-form-item-label {
  line-height: 18px;
}
.antd-pro-component-dashs-card-index-CardAntd .ant-form-item-control,
.antd-pro-component-dashs-card-index-CardAntd .ant-legacy-form-item-control {
  line-height: 45px;
}
.antd-pro-component-dashs-card-index-CardAntd .styleBorder {
  border: 1px solid #1175BB;
  padding: 15px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 10px;
}
.antd-pro-component-dashs-card-index-CardAntd .styleBorder .ant-form-item-control.has-error,
.antd-pro-component-dashs-card-index-CardAntd .styleBorder .ant-legacy-form-item-control.has-error {
  margin-bottom: 30px;
}
.antd-pro-component-dashs-card-index-CardAntd form textarea.ant-input {
  margin-bottom: 5.52px;
}
.antd-pro-component-dashs-card-index-CardAntd .ant-form-item-label > label,
.antd-pro-component-dashs-card-index-CardAntd .ant-legacy-form-item-label > label {
  display: inline-flex;
  font-size: 13px;
  height: 100%;
}
.antd-pro-component-dashs-card-index-CardAntdNoLegacy .ant-card-body {
  padding: 15px;
}
.antd-pro-component-dashs-card-index-CardAntdNoLegacy .ant-form-item-label {
  line-height: 18px;
}
.antd-pro-component-dashs-card-index-CardAntdNoLegacy .ant-form-item-control {
  line-height: 45px;
}
.antd-pro-component-dashs-card-index-CardAntdNoLegacy .styleBorder {
  border: 1px solid #1175BB;
  padding: 15px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 10px;
}
.antd-pro-component-dashs-card-index-CardAntdNoLegacy .styleBorder .ant-form-item-control.has-error {
  margin-bottom: 30px;
}
.antd-pro-component-dashs-card-index-CardAntdNoLegacy form textarea.ant-input {
  margin-bottom: 5.52px;
}
.antd-pro-component-dashs-card-index-CardAntdNoLegacy .ant-form-item-label > label {
  display: inline-flex;
  font-size: 13px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .antd-pro-component-dashs-card-index-CardAntd .address {
    margin-top: -40px;
  }
}
