/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.baseView {
  display: flex;
  padding-top: 12px;
}
.baseView .left {
  max-width: 600px;
  min-width: 500px;
}
.baseView .right {
  flex: 1;
  min-width: 200px;
  padding: 10px;
}
.baseView .right .avatar_title {
  height: 22px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  margin-bottom: 8px;
}
.baseView .right .avatar {
  margin: auto;
}
.baseView .right .button_view {
  width: 144px;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .baseView {
    flex-direction: column;
  }
  .baseView .right {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    max-width: 448px;
  }
  .baseView .right .avatar_title {
    display: none;
  }
  .baseView .left {
    min-width: 100%;
  }
}
