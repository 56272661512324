.antd-pro-component-dashs-common-index-bodyquizz {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 0px;
  z-index: 9999;
  background: #fff;
}
.antd-pro-component-dashs-common-index-quizz {
  font-size: 16px;
  font-weight: 600;
}
.antd-pro-component-dashs-common-index-headerAnswer {
  text-transform: capitalize;
}
.antd-pro-component-dashs-common-index-inputAnswer {
  border: unset !important;
  border-bottom: 1px solid #eee !important;
  box-shadow: unset !important;
}
.antd-pro-component-dashs-common-index-answerContent {
  display: flex;
  height: 23px;
}
.antd-pro-component-dashs-common-index-iconSort {
  margin-left: 10px;
  display: none;
  cursor: pointer;
}
.antd-pro-component-dashs-common-index-iconSort span {
  font-size: 20px;
}
.antd-pro-component-dashs-common-index-answerContent:hover .antd-pro-component-dashs-common-index-iconSort {
  display: block;
}
.antd-pro-component-dashs-common-index-bodyContentQuizz {
  border: 1px solid #eee;
  padding: 10px;
}
