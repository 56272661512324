.styleCreateCard {
  width: 100%;
  min-height: 350px;
  border-radius: 4px;
  height: auto;
  border: 1px solid #bbb;
  text-align: center;
}
.styleCreateCardProduct {
  width: 100%;
  min-height: 250px;
  height: auto;
  border: 1px solid #bbb;
  text-align: center;
}
.titleInput {
  margin-bottom: 10px;
}
.priceInput {
  width: 45%;
  float: left;
}
.unitInput {
  width: 45%;
  float: right;
}
.iconCard {
  position: absolute;
  top: 3px;
  right: 3px;
  background: #f96359;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.iconCard span {
  opacity: 0;
  font-size: 10px;
  position: absolute;
  right: 3px;
  top: 3px;
  cursor: pointer;
}
.iconCard span:hover {
  opacity: 1;
}
.contentInput {
  margin-bottom: 10px;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
  box-shadow: unset !important;
}
.titleListService {
  font-weight: 500;
  font-size: 25px;
  /* line-height: 39px; */
  text-align: center;
  color: #2d6b95;
}
.contentSelect .ant-select-selector {
  border: unset !important;
  border-bottom: 1px solid #d9d9d9 !important;
  box-shadow: unset !important;
}
