.antd-pro-component-dashs-catalog-index-contentSelect {
  display: flex;
}
.antd-pro-component-dashs-catalog-index-lable {
  width: 60%;
}
.antd-pro-component-dashs-catalog-index-categories {
  width: 30%;
  font-weight: bold;
}
.antd-pro-component-dashs-catalog-index-selected {
  width: 10%;
  color: red;
  float: right;
}
.antd-pro-component-dashs-catalog-index-selectSearch {
  margin-bottom: 10px;
}
.antd-pro-component-dashs-catalog-index-selectSearch .ant-select-selector {
  border: unset !important;
  border-bottom: 1px solid #d9d9d9!important;
  border-radius: 0!important;
  box-shadow: unset!important;
}
.antd-pro-component-dashs-catalog-index-inputSearch {
  margin-bottom: 10px;
  border: unset !important;
  border-bottom: 1px solid #d9d9d9!important;
  border-radius: 0!important;
  box-shadow: unset!important;
}
.antd-pro-component-dashs-catalog-index-treeCategory > div {
  border: unset !important;
  border-bottom: 1px solid #d9d9d9!important;
  border-radius: 0!important;
  box-shadow: unset!important;
}
.antd-pro-component-dashs-catalog-index-tabChuyenTrang .ant-tabs-nav {
  margin: 0;
}
