.topTabs {
  width: 100%;
}
.topTabs .ant-tabs-bar {
  border: unset;
  margin: unset;
}
.topTabs .ant-tabs-nav {
  width: 100%;
  border: unset;
  margin: unset;
}
.topTabs .ant-tabs-nav-list {
  width: 100%;
}
.topTabs .ant-tabs-tab {
  border: unset!important;
  background: unset;
  font-size: 15px!important;
  padding: 10px;
}
.topTabs .ant-tabs-tab-active {
  border-radius: 10px 10px 0 0!important;
  background: #ebebeb !important;
  color: #1175BB!important;
}
.topTabs .ant-tabs-ink-bar {
  display: none !important;
}
.topTabs .ant-tabs-tabpane {
  background: #ebebeb !important;
  padding: 15px;
}
.topTabss .ant-tabs-tab {
  font-size: 16px;
}
.iconCardCloseCollec {
  position: absolute;
  top: 3px;
  right: 25px;
  background: #f96359;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  font-size: 10px;
}
.iconCardSettingCollec {
  position: absolute;
  top: 3px;
  right: 3px;
  background: #FCC130;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
.iconCardCloseCollec span,
.iconCardSettingCollec span {
  opacity: 0;
  font-size: 10px;
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
}
.iconCardCloseCollec span:hover,
.iconCardSettingCollec span:hover {
  opacity: 1;
}
.title_dia_diem {
  text-align: center;
  margin: 20px 0px;
  font-size: 20px;
  font-weight: 400;
  color: #2D6B8C;
  text-decoration: underline;
}
