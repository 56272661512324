.fileNbmInput {
  display: none;
}

.fileNBM-upload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.fileNBM-upload .fileNBM-select {
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
}

.fileNBM-upload .fileNBM-select .fileNBM-select-button {
  background: #dce4ec;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.fileNBM-upload .fileNBM-select .fileNBM-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.fileNBM-upload .fileNBM-select:hover {
  border-color: #34495e;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.fileNBM-upload .fileNBM-select:hover .fileNBM-select-button {
  background: #34495e;
  color: #FFFFFF;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.fileNBM-upload.active .fileNBM-select {
  border-color: #3fa46a;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.fileNBM-upload.active .fileNBM-select .fileNBM-select-button {
  background: #3fa46a;
  color: #FFFFFF;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.fileNBM-upload .fileNBM-select input[type=file] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* opacity:0; */
  display: none;
  filter: alpha(opacity=0);
}

.fileNBM-upload .fileNBM-select.fileNBM-select-disabled {
  opacity: 0.65;
}

.fileNBM-upload .fileNBM-select.fileNBM-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
}

.fileNBM-upload .fileNBM-select.fileNBM-select-disabled:hover .fileNBM-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.fileNBM-upload .fileNBM-select.fileNBM-select-disabled:hover .fileNBM-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}