.bodyView {
  padding: 24px 24px 30px;
  background: #fff;
}
.bodyViewHuy {
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  background: #fffcf5;
}
.bodyViewHeader {
  display: flex;
}
.createDate {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.titleOrderViewHeader {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  line-height: 24px;
  width: 50%;
}
.titleOrderView {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  line-height: 24px;
  padding: 0px 24px 12px;
  background: #fff;
}
.titleOrderViewHuy {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  line-height: 24px;
  padding: 0px 24px 12px;
  color: #ee4d2d;
  font-weight: 500;
}
.titleOrderViewShopHuy {
  padding: 0 24px;
  display: flex;
}
.titleOrderViewShop {
  padding: 0 24px;
  display: flex;
  background: #fff;
}
.userShops {
  color: #ee4d2d;
  font-size: 15px;
  font-weight: bold;
}
.userShopsLh {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 10px;
}
.mobile,
.shippingAddress {
  color: rgba(0, 0, 0, 0.54);
  white-space: pre-line;
}
.fullname {
  font-size: 15px;
  max-width: 100%;
  margin: 0px 0px 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.8);
}
.cellTable {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: end;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.09);
  border-right: 1px dotted rgba(0, 0, 0, 0.09);
}
.imageProduct {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.09);
}
.bodyimageProductimage {
  display: flex;
  padding: 10px 24px;
  border-top: 1px dotted rgba(0, 0, 0, 0.09);
  background: #fff;
}
.imageProductimage {
  width: 70%;
  display: flex;
}
.totalLable {
  width: 70%;
  display: flex;
  text-align: right;
  font-size: 18px;
  padding: 10px 0;
}
.nameProductandso {
  padding-left: 20px;
}
.nameProduct {
  margin: 0 0 2px;
  font-size: 16px;
}
.gia {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
.price {
  margin: 0 4px 0 0;
  -webkit-text-decoration-line: line-through;
  -moz-text-decoration-line: line-through;
  text-decoration-line: line-through;
  color: #000;
  opacity: .26;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.dealPrice {
  color: #ee4d2d;
  font-size: 16px;
}
.total {
  color: #ee4d2d;
  font-size: 20px;
  padding: 10px 0;
}
