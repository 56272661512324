.ck-block-toolbar-button {
  z-index: 9999 !important;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  /* line-height: 1.5rem; */
}

#fullscreeneditor {
  position: fixed !important;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  height: calc(100vh - 45px);
  background: #fff;
}
.ck-editor-modal-buton .ant-modal-content{
  background: #fff !important;
  border-radius: unset !important;
}
.ck-editor-modal-buton .ant-modal-content .ant-modal-close{
  display: none;
}
.ck-editor-modal-buton .ant-modal-content .ant-modal-header{
  padding: 5px 10px;
}
.ck-editor-modal-buton .ant-modal-content .ant-modal-body{
  padding: 10px;
}
.ck-editor-modal-buton .ant-modal-content .ant-modal-header .ant-modal-title{
  font-size: 14px;
    font-weight: 600;
}
.backgroundButtoninput .ant-input-group-addon .ant-select-open .ant-select-selector, .ant-input-group-addon .ant-select-focused .ant-select-selector{
color: rgba(0, 0, 0, 0.85);
}
.backgroundButtoninput .ant-input-group-addon:last-child{
  padding: unset !important;
}